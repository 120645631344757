import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { IStatuses } from 'app/shared/model/statuses.model';
import { IAdminPlaylist } from './admin-playlist-model';

export interface IAdminAssociationsManagement {
  id?: number;
  updatedDate?: string;
  serialNumber?: string;
  publishDate?: string;
  keywords?: string;
  videotype?: string;
  subscriptionPlan?: ISubscriptionPlan;
  statuses?: IStatuses;
  privacyStatus?: string;
  audioTitle?: string;
  padTitle?: string;
  patraTitle?: string;
  ebookTitle?: string;
  title?: string;
  language?: string;
  status?: string;
  thumbnailImageUrl?: string;
  bookImage?: string;
  contentUrl?: string;
  contentStorage?: string;
  noOfLikes?: string;
  noOfViews?: string;
  refContentId?: string;

  speaker?: string;
  thumbnailBlobName?: string;
  imageBlobName?: string;
  contentUrlBlobName?: string;

  satsangDate?: string;

  indianDate?: string;
  patraNo?: number;

  contentUrlApple?: string;
  contentUrlSpotify?: string;
  writtenTo?: string;
  author?: string;
  yearOfPatra?: string;
  publisher?: string;
  comments?: string;
  satsangTopic?: string;
  version?: string;
  durationTotalMin?: string;

  pageCount?: string;

  // playlist?:IAdminPlaylist
  videoUrl?: string;
  playlistName?: string;
  contentIds?: number[];
  systemPlayList?: { id: number };
  unselectContentIds?: number[];
  thumbnail?: boolean;
  noOfVideos?: number;
  noOfaudios?: number;
  noOfEbooks?: number;
  noOfPads?: number;
  noOfPatras?: number;
  serialNo?: number;
  videoTitle?: string;
  imageUrl?: string;
}

export const defaultValue: Readonly<IAdminAssociationsManagement> = {};
