import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YOUTTUBE_AUDIO_URL } from 'app/config/constants';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import 'draft-js/dist/Draft.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Label, Row } from 'reactstrap';
import FormUpload from './admin-sparsh-management-thumbnail-upload';
import { getEntity, getLyrics, uploadEntity, uploadThumbnail } from './admin-sparsh-reducer';

export interface IAdminSparshLyricsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      trackId: any;
      id?: string;
      association: string;
      url: string;
      liveVideoId: string;
    }> {}

export const AdminSparshUploadThumbnail = (props: IAdminSparshLyricsProps) => {
  const { associationEntity, loading, updating, entity } = props;
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [lyrics, setLyrics] = useState(null);
  const [file, setFile] = useState<any>(null);
  const [isPreview, setPreview] = useState(props?.entity?.content?.[0]?.thumbnailUrl);
  // src={props?.entity?.content?.[0]?.thumbnailUrl}
  // const [albumId, setPreview] = useState(props?.entity['content'][0]?.thumbnailUrl);
  // const [isPreview, setPreview] = useState(true);
  const [fileShow, setFileShow] = useState(null);

  const [isImageError, setImageError] = useState<boolean>(false);

  const handleGoBack = () => {
    props.history.goBack();
  };

  const isImageSizeAppropriate = img => {
    let maxWidth;
    let maxHeight;
    maxWidth = 600;
    maxHeight = 600;

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(img);

      image.onload = () => {
        // if (image.width == maxWidth && image.height == maxHeight) {
        if (image.width === image.height) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      image.onerror = () => {
        reject('Failed to load image.');
      };
    });
  };
  const convertBase64 = files => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);

      fileReader.onload = () => {
        resolve(fileReader.result);
        setFileShow(fileReader.result);
        setImageError(false);
      };

      fileReader.onerror = error => {
        reject(error);
        setImageError(true);
      };
    });
  };

  const uploadSingleFile = async e => {
    if (e?.target?.files[0]) {
      const file = e.target.files[0];
      const name = e.target.name;

      try {
        const isSizeAndDimensionsAppropriate = await isImageSizeAppropriate(file);

        if (isSizeAndDimensionsAppropriate) {
          setPreview(true);
          setFile(file);
          convertBase64(file);
        } else {
          setImageError(true);
          toast.error('Please select an image file with equal dimensions (eg. 600x600).');
        }
      } catch (error) {
        // Handle any errors that occur while loading the image
        console.error(error);
      }
    }
  };

  // useEffect(() => {
  //   const fetchLyrics = async () => {
  //     await props.getLyrics(props.match.params.trackId);
  //   };
  //   fetchLyrics();
  // }, [props.match.params.trackId, props.getLyrics]);

  // useEffect(() => {
  //   if (props.data?.data?.lyrics) {
  //     setLyrics(props.data.data.lyrics);
  //   }
  // }, [props.data]);

  // useEffect(() => {
  //   setContent(lyrics);
  // }, [lyrics]);

  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [albumDetails, setAlbumDetails] = useState(null);

  useEffect(() => {
    const album = props?.entity?.content?.[0]?.audioAlbum;
    setAlbumDetails(album);
  }, [props.entity]);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      height: 500,
      width: 500,
    }),
    []
  );

  function onChangeHandler(lyrics: string): void {
    setLyrics(lyrics);
  }

  // const handleFormSubmit = async event => {
  //   if (!lyrics) return;
  //   let result = await props?.uploadEntity(props.match.params.trackId, lyrics);
  //   if (result) {
  //     handleGoBack();
  //   }
  // };
  const handleFormSubmitThumbnail = async event => {
    if (!file) return;
    let result = await props?.uploadThumbnail(props.match.params.trackId, file);
    if (result) {
      handleGoBack();
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={handleGoBack}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Upload Thumbnail</h2>
            </div>
          </div>
        </Col>

        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : associationEntity}>
              <div className="row">
                {/* Left Side */}
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="id" for="id" className="label-font">
                      Album No.*
                    </Label>
                    <AvField id="id" type="text" name="id" className="input-font" value={props.match.params.trackId} disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="add1" for="add1" className="label-font">
                      Title*
                    </Label>
                    <AvField id="add1" type="text" name="add1" className="input-font" value={albumDetails?.title} disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="add2" for="add2" className="label-font">
                      Album Id*
                    </Label>
                    <AvField id="add2" type="text" name="add2" className="input-font" value={albumDetails?.albumId} disabled />
                  </AvGroup>
                </div>

                {/* Right Side */}
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="id-right" for="id-right" className="label-font">
                      Total Tracks*
                    </Label>
                    <AvField id="id-right" type="text" name="id-right" className="input-font" value={albumDetails?.totalTracks} disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="add1-right" for="add1-right" className="label-font">
                      Album URL*
                    </Label>
                    <AvField
                      id="add1-right"
                      type="text"
                      name="add1-right"
                      className="input-font"
                      value={`${YOUTTUBE_AUDIO_URL}${albumDetails?.albumId}`}
                      disabled
                    />
                  </AvGroup>

                  {/* <AvGroup className="input-box-width">
                  <Label id="add2-right" for="add2-right" className="label-font">
                    Add 2*
                  </Label>
                  <AvField id="add2-right" type="text" name="add2-right" className="input-font" value="Value 2" disabled />
                </AvGroup> */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="image-container">
                    <FormUpload
                      label={'Upload Thumbnail'}
                      errorMsg={isImageError}
                      inlineLabel={false}
                      value={null}
                      style={{ height: 150 }}
                      uploadSingleFile={uploadSingleFile}
                    />
                    {fileShow ? (
                      <FormUpload
                        isPreview={isPreview}
                        inlineLabel={false}
                        value={fileShow}
                        style={{ height: 150 }}
                        uploadSingleFile={uploadSingleFile}
                      />
                    ) : (
                      <>
                        {entity &&  (props?.entity?.content?.[0]?.thumbnailUrl) && (
                          <img src={props?.entity?.content?.[0]?.thumbnailUrl} style={{ height: 150, marginTop: 20 }} alt="Thumbnail" />
                        )}
                      </>
                    )}
                  </div>
                  <button
                    disabled={updating}
                    id="upload-thumbnail"
                    type="button"
                    className="common-btn"
                    style={{
                      width: 200,
                      marginTop: 20,
                    }}
                    onClick={handleFormSubmitThumbnail} 
                  >
                    <span className="header-title">Upload Thumbnail</span>
                  </button>
                </div>
              </div>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  associationEntity: storeState.liveVideoList.entity,
  loading: storeState.liveVideoList.loading,
  updating: storeState.liveVideoList.updating,
  updateSuccess: storeState.liveVideoList.updateSuccess,
  data: storeState.sparshAlbumManagement.lyricsEntity,
  entity: storeState.sparshAlbumManagement.entity,
});

const mapDispatchToProps = {
  getStatuses,
  uploadEntity,
  uploadThumbnail,
  getLyrics,
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshUploadThumbnail);
