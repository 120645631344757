import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, Storage } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IVideoMetaData, defaultValue } from 'app/shared/model/video-meta-data.model';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ACTION_TYPES = {
  FETCH_VIDEOMETADATA_LIST: 'videoMetaData/FETCH_VIDEOMETADATA_LIST',
  FETCH_SEARCH_LIST: 'videoMetaData/FETCH_SEARCH_LIST',
  FETCH_VIDEOMETADATA: 'videoMetaData/FETCH_VIDEOMETADATA',
  CREATE_VIDEOMETADATA: 'videoMetaData/CREATE_VIDEOMETADATA',
  UPDATE_VIDEOMETADATA: 'videoMetaData/UPDATE_VIDEOMETADATA',
  DELETE_VIDEOMETADATA: 'videoMetaData/DELETE_VIDEOMETADATA',
  FETCH_VIDEOMETADATA_TEMPLATE: 'videoMetaData/FETCH_VIDEOMETADATA_TEMPLATE',
  FETCH_SERACH_VIDEO_LIST: 'videoMetaData/FETCH_SERACH_VIDEO_LIST',
  UPLOAD_CSV_FILE: 'videoMetaData/UPLOAD_CSV_FILE',
  SYNC_YOUTUB_VIDEO: 'videoMetaData/SYNC_YOUTUB_VIDEO',
  VIDEO_DOWNLOAD_LIST: 'videoMetaData/VIDEO_DOWNLOAD_LIST',
  RESET: 'videoMetaData/RESET',
  FETCH_ASSOCATED_WITH_LIST: 'videoMetaData/FETCH_ASSOCATED_WITH_LIST',
  REMOVE_ASSOCIATED_WITH: 'videoMetaData/REMOVE_ASSOCIATED_WITH',
  SET_INDEXING: 'videoMetaData/SET_INDEXING',
  UPLOAD_TRACK: 'videoMetaData/UPLOAD_TRACK',
  DELETE_TRACK: 'videoMetaData/DELETE_TRACK',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVideoMetaData>,
  entity: defaultValue,
  updating: false,
  loadingUpload:false,
  totalItems: 0,
  updateSuccess: false,
  template: '',
  uploadCsv: '',
  videoSync: '',
  videoList: [],
  loadingFile: null,
  youtubVideoSync: null,
  isBeingDeleted: false,
};

export type VideoMetaDataState = Readonly<typeof initialState>;

// Reducer

export default (state: VideoMetaDataState = initialState, action): VideoMetaDataState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VIDEOMETADATA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ASSOCATED_WITH_LIST):

    case REQUEST(ACTION_TYPES.FETCH_SEARCH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VIDEOMETADATA_TEMPLATE):
    // case REQUEST(ACTION_TYPES.VIDEO_DOWNLOAD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VIDEOMETADATA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        entities: [],
      };
    case REQUEST(ACTION_TYPES.FETCH_SERACH_VIDEO_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.CREATE_VIDEOMETADATA):
    case REQUEST(ACTION_TYPES.UPDATE_VIDEOMETADATA):
    case REQUEST(ACTION_TYPES.SET_INDEXING):

    case REQUEST(ACTION_TYPES.DELETE_VIDEOMETADATA):
    case REQUEST(ACTION_TYPES.DELETE_TRACK):
    case REQUEST(ACTION_TYPES.REMOVE_ASSOCIATED_WITH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.UPLOAD_CSV_FILE):
      return {
        ...state,
        loadingFile: true,
      };
    case REQUEST(ACTION_TYPES.SYNC_YOUTUB_VIDEO):
      return {
        ...state,
        youtubVideoSync: true,
      };

    case REQUEST(ACTION_TYPES.UPLOAD_TRACK):
      return {
        ...state,
        entities: [],
        loadingUpload:true
      };


    case FAILURE(ACTION_TYPES.FETCH_VIDEOMETADATA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ASSOCATED_WITH_LIST):

    case FAILURE(ACTION_TYPES.FETCH_SEARCH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VIDEOMETADATA_TEMPLATE):
    case FAILURE(ACTION_TYPES.UPLOAD_CSV_FILE):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        loadingFile: false,
      };

    case FAILURE(ACTION_TYPES.SYNC_YOUTUB_VIDEO):
      return {
        ...state,
        youtubVideoSync: false,
      };
    case FAILURE(ACTION_TYPES.FETCH_VIDEOMETADATA):
    case FAILURE(ACTION_TYPES.CREATE_VIDEOMETADATA):
    case FAILURE(ACTION_TYPES.UPDATE_VIDEOMETADATA):
    case FAILURE(ACTION_TYPES.DELETE_VIDEOMETADATA):
    case FAILURE(ACTION_TYPES.DELETE_TRACK):
    case FAILURE(ACTION_TYPES.VIDEO_DOWNLOAD_LIST):
    case FAILURE(ACTION_TYPES.REMOVE_ASSOCIATED_WITH):
    case FAILURE(ACTION_TYPES.SET_INDEXING):
      return {
        ...state,
        loading: false,

        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_SERACH_VIDEO_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case FAILURE(ACTION_TYPES.UPLOAD_TRACK):
      return {
        ...state,
        loadingFile: false,
        updateSuccess: false,
        loadingUpload: false,
      };
  

    case SUCCESS(ACTION_TYPES.VIDEO_DOWNLOAD_LIST):
      return {
        ...state,
        loading: false,
        videoList: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VIDEOMETADATA_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_ASSOCATED_WITH_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_SEARCH_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_VIDEOMETADATA_TEMPLATE):
      return {
        ...state,
        loading: false,
        template: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_CSV_FILE):
      return {
        ...state,
        loading: false,
        uploadCsv: action.payload.data,
        loadingFile: false,
      };
    case SUCCESS(ACTION_TYPES.SYNC_YOUTUB_VIDEO):
      return {
        ...state,
        loading: false,
        youtubVideoSync: false,
        videoSync: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.FETCH_VIDEOMETADATA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VIDEOMETADATA):
    case SUCCESS(ACTION_TYPES.UPDATE_VIDEOMETADATA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.REMOVE_ASSOCIATED_WITH):
    case SUCCESS(ACTION_TYPES.SET_INDEXING):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VIDEOMETADATA):
      return {
        ...state,
        entity: {},
        updating: false,
        updateSuccess: true,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.DELETE_TRACK):
      return {
        ...state,
        entity: {},
        updating: false,
        updateSuccess: true,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.UPLOAD_TRACK):
      return {
        ...state,
        loading: false,
        loadingUpload: false,
        updateSuccess: true,
        // entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/video-meta-data';

// Actions

export const getEntities: ICrudPutAction<IVideoMetaData> = (page, size, sort, order, params) => {
  const data = {
    searchValue: params?.searchValue,
    sortColumn: sort,
    sortDirection: order.toUpperCase(),
    pageNo: page,
    pageSize: size,
  };
  const requestUrl = `api/video-meta-data/search`;
  // const requestUrl = `api/video-meta-data/search/{searchValue}?searchValue=${params}page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_VIDEOMETADATA_LIST,
    payload: axios.post<IVideoMetaData>(requestUrl, data),
  };
};
export const getAttachments: ICrudPutAction<IVideoMetaData> = params => {
  const requestUrl = `api/contents/video/admin`;
  // const requestUrl = `api/video-meta-data/search/{searchValue}?searchValue=${params}page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_ASSOCATED_WITH_LIST,
    payload: axios.post<IVideoMetaData>(requestUrl, params),
  };
};
export const getPlaylists: ICrudPutAction<IVideoMetaData> = params => {
  const requestUrl = `api/system/playlists/content`;
  // const requestUrl = `api/video-meta-data/search/{searchValue}?searchValue=${params}page=${page}&size=${size}`;
  return {
    type: ACTION_TYPES.FETCH_ASSOCATED_WITH_LIST,
    payload: axios.post<IVideoMetaData>(requestUrl, params),
  };
};
export const removePlaylist: ICrudDeleteAction<IVideoMetaData> = (
  videoId,
  playListId,
  page,
  size,
  sort,
  order,
  search
) => async dispatch => {
  const deleteUrl = 'api/system/playlist/content/delete';
  const requestUrl = `${deleteUrl}/${videoId}/${playListId}`;
  const result = await dispatch({
    type: ACTION_TYPES.REMOVE_ASSOCIATED_WITH,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Deleted !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const playlistData = {
    searchValue: search,
    pageNo: page,
    pageSize: size,
    contentId: videoId, // video id
    contentType: 'VIDEO',
    sortColumn: sort,
    sortDirection: order,
  };
  // dispatch(getAssociationLists(page, size, sort, playListId));
  dispatch(getPlaylists(playlistData));
  return result;
};
export const removeAssociation: ICrudDeleteAction<IVideoMetaData> = (
  associationId,
  association,
  contentType,
  searchValue,
  videoId,
  page,
  size,
  sort,
  sortDirection
) => async dispatch => {
  const requestUrl = `api/content/delete/${associationId}/${contentType}/${videoId}/VIDEO`;
  const result = await dispatch({
    type: ACTION_TYPES.REMOVE_ASSOCIATED_WITH,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Removed !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const associationData = {
    searchValue: searchValue,
    sortColumn: sort,
    sortDirection: sortDirection,
    pageNo: page,
    pageSize: size,
    contentType: contentType.toUpperCase(),
    referencesContentId: videoId,
  };
  dispatch(getAttachments(associationData));
  return result;
};

export const getTemplate = () => {
  const requestUrl = `api/templateDownload2`;
  const anchor = document.createElement('a');
  let headers = new Headers();
  const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
  headers.append('Authorization', `Bearer ${token}`);
  fetch(requestUrl, { headers })
    .then(response => response.blob())
    .then(blobby => {
      if (blobby.type == 'application/octet-stream') {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = 'VideoMetaDataFormat.xlsx';
        anchor.click();
        anchor.remove();
        window.URL.revokeObjectURL(objectUrl);
        toast.success('Successfully downloaded !', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });

  // return {
  //   type: ACTION_TYPES.FETCH_VIDEOMETADATA_TEMPLATE,
  //   payload: axios.get<IVideoMetaData>(requestUrl)
  // };
};
export const downloadVideoMetaData = () => {
  const requestUrl = `/api/video-meta-data/exportToExcel`;
  const anchor = document.createElement('a');
  let headers = new Headers();
  const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
  headers.append('Authorization', `Bearer ${token}`);
  fetch(requestUrl, { headers })
    .then(response => response.blob())
    .then(blobby => {
      if (blobby.type == 'application/octet-stream') {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = 'VideoMetaDataFormat.xlsx';
        anchor.click();
        anchor.remove();
        window.URL.revokeObjectURL(objectUrl);
        toast.success('Successfully downloaded !', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
};

export const uploadCSVFile: ICrudPutAction<IVideoMetaData> = (file, page, size, sort, order) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPLOAD_CSV_FILE,
    payload: axios.post(`${apiUrl}/upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // timeout: 1000000 * 5,
    }),
  });
  if (result.value.status == 200) {
    toast.success('Successfully uploaded !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch(setIndexing(null));
  }
  dispatch(getEntities(page, size, sort, order));
  return result;
};

export const searchVideoList: ICrudPutAction<IVideoMetaData> = (page, size, sort, order, params) => {
  const data = {
    searchValue: params.searchValue,
    sortColumn: sort,
    sortDirection: order.toUpperCase(),
    pageNo: page,
    pageSize: size,
  };
  const requestUrl = 'api/video-meta-data/search';
  return {
    type: ACTION_TYPES.FETCH_SEARCH_LIST,
    payload: axios.post(requestUrl, cleanEntity(data)),
  };
};

export const getEntity: ICrudGetAction<IVideoMetaData> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VIDEOMETADATA,
    payload: axios.get<IVideoMetaData>(requestUrl),
  };
};
export const setIndexing: ICrudGetAction<IVideoMetaData> = id => {
  const requestUrl = `api/index`;
  return {
    type: ACTION_TYPES.SET_INDEXING,
    payload: axios.get<IVideoMetaData>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IVideoMetaData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VIDEOMETADATA,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  if (result.value.status == 200) {
    dispatch(setIndexing(null));
  }
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IVideoMetaData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VIDEOMETADATA,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  if (result.value.status == 200) {
    dispatch(setIndexing(null));
  }
  return result;
};

export const deleteEntity: ICrudDeleteAction<IVideoMetaData> = (id, page, size, sort) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VIDEOMETADATA,
    payload: axios.delete(requestUrl),
  });
  // dispatch(getEntities(page, size, sort));
  if (result.value.status == 204) {
    dispatch(setIndexing(null));
  }

  return result;
};

export const syncYoutubVideo: ICrudPutAction<IVideoMetaData> = (parmas, page, size, sort, order) => async dispatch => {
  const Url = 'api/video-meta-data/youtubeVideoData';
  const result = await dispatch({
    type: ACTION_TYPES.SYNC_YOUTUB_VIDEO,
    payload: axios.post(Url, parmas),
  });
  if (result.value.status == 200) {
    toast.success('Successfully video sync !', {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch(setIndexing(null));
  }
  dispatch(getEntities(page, size, sort, order));
  return result;
};

export const videoDownload: ICrudPutAction<IVideoMetaData> = parmas => async dispatch => {
  const Url = 'api/video-meta-data/details';
  const result = await dispatch({
    type: ACTION_TYPES.VIDEO_DOWNLOAD_LIST,
    payload: axios.get<IVideoMetaData>(Url),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const uploadTrack = (audioTrackId, file) => async dispatch => {
  const requestUrl = `api/v3/video-meta-datas/update-audiofile`;
  const formData = new FormData();
  formData.append('id', audioTrackId);
  formData.append('audioFile', file);

  try {
    const result = await dispatch({
      type: ACTION_TYPES.UPLOAD_TRACK,
      payload: axios.put(requestUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    });

    if (result.value.status === 200) {
      toast.success('Successfully Uploaded!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return result;
  } catch (error) {
    toast.error('Something went wrong!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

export const deleteTrack: ICrudDeleteAction<IVideoMetaData> = (id) => async dispatch => {
  const requestUrl = `api/v3/video-meta-datas/delete-audiofile/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TRACK,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Deleted!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return result;
};
