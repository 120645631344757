import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, Storage } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IAdminAssociationsManagement, defaultValue } from 'app/shared/model/admin-associations-managemen-model';
export const ACTION_TYPES = {
  RESET: 'adminAssociation/RESET',
  FETCH_EXISTING_ASSOCIATIONS_IDS: 'adminAssociation/FETCH_EXISTING_ASSOCIATIONS_IDS',
  ADD_ATTACHMENTS: 'adminAssociation/ADD_ATTACHMENTS',
  FETCH_ENTITIES: 'adminAssociation/FETCH_ENTITIES',
  FETCH_ENTITY: 'adminAssociation/FETCH_ENTITY',
  UPDATE_ENTITY: 'adminAssociation/FETCH_ENTITY',
  DELETE_ENTITY: 'adminAssociation/DELETE_ENTITY',
  UPLOAD_ENTITY: 'adminAssociation/UPLOAD_ENTITY',
  REMOVE_PLAYLIST_ASSOCIATION: 'adminAssociation/REMOVE_PLAYLIST_ASSOCIATION',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAdminAssociationsManagement>,

  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  template: '',
  loadingFile: null,
  existingSelectedIds: [] as Array<number>,
  isEntityLoad: false,
  // existingPlaylistIds: [] as ReadonlyArray<number>,
};

export type AdminAssociationManagemetState = Readonly<typeof initialState>;

// Reducer

export default (state: AdminAssociationManagemetState = initialState, action): AdminAssociationManagemetState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_EXISTING_ASSOCIATIONS_IDS):
    case REQUEST(ACTION_TYPES.FETCH_ENTITY):
      // case REQUEST(ACTION_TYPES.FETCH_ENTITIES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_ENTITIES):
      return {
        ...state,
        entities: [],
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        isEntityLoad: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
    case REQUEST(ACTION_TYPES.DELETE_ENTITY):
    case REQUEST(ACTION_TYPES.UPLOAD_ENTITY):
    case REQUEST(ACTION_TYPES.ADD_ATTACHMENTS):
    case REQUEST(ACTION_TYPES.REMOVE_PLAYLIST_ASSOCIATION):
      return {
        ...state,
        // loading: true,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_ENTITIES):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
      };
    case FAILURE(ACTION_TYPES.UPLOAD_ENTITY):
    case FAILURE(ACTION_TYPES.DELETE_ENTITY):
    case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
    case FAILURE(ACTION_TYPES.ADD_ATTACHMENTS):
    case FAILURE(ACTION_TYPES.FETCH_ENTITY):
    case FAILURE(ACTION_TYPES.REMOVE_PLAYLIST_ASSOCIATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,

        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_ENTITIES):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        isEntityLoad: false,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_EXISTING_ASSOCIATIONS_IDS):
      return {
        ...state,
        loading: false,
        existingSelectedIds: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.FETCH_ENTITY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_ENTITY):
    case SUCCESS(ACTION_TYPES.ADD_ATTACHMENTS):
    case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
    case SUCCESS(ACTION_TYPES.REMOVE_PLAYLIST_ASSOCIATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        // entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ENTITY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
export const getEntities: ICrudGetAllAction<IAdminAssociationsManagement> = (page, size, sort, association, searchText = null) => {
  const entityUrl = `api/${association}s/${searchText}`;
  const requestUrl = `${entityUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ' '}`;

  return {
    type: ACTION_TYPES.FETCH_ENTITIES,
    payload: axios.get<IAdminAssociationsManagement>(`${requestUrl}`),
  };
};
export const getAssociatedPlaylists: ICrudPutAction<IAdminAssociationsManagement> = (params: any) => {
  const entityUrl = `api/system/playlists/content `;

  return {
    type: ACTION_TYPES.FETCH_ENTITIES,
    payload: axios.post<IAdminAssociationsManagement>(`${entityUrl}`, params),
  };
};
export const getAssociatedVideos: ICrudPutAction<IAdminAssociationsManagement> = (params: any) => {
  const entityUrl = `api/video-meta-datas/content `;

  return {
    type: ACTION_TYPES.FETCH_ENTITIES,
    payload: axios.post<IAdminAssociationsManagement>(`${entityUrl}`, params),
  };
};

export const getEntity: ICrudGetAction<IAdminAssociationsManagement> = (id, association) => {
  const getEntityUrl = `api/${association}`;
  const requestUrl = `${getEntityUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ENTITY,
    payload: axios.get<IAdminAssociationsManagement>(requestUrl),
  };
};
export const updateEntity: ICrudPutAction<IAdminAssociationsManagement> = (entity, association) => async dispatch => {
  const createUrl = `api/${association}/update`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ENTITY,
    payload: axios.put(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return result;
};
export const uploadEntity: ICrudPutAction<IAdminAssociationsManagement> = (param: any, association: any) => async dispatch => {
  const createUrl = ` api/${association}/save`;
  const result = await dispatch({
    type: ACTION_TYPES.UPLOAD_ENTITY,
    payload: axios.post(createUrl, param),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Created!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return result.value.data;
};

export const deleteEntity: ICrudDeleteAction<IAdminAssociationsManagement> = (id, association, page, sort, params) => async dispatch => {
  const deleteUrl = `api/${association}/delete`;
  const requestUrl = `${deleteUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ENTITY,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Deleted !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  dispatch(getEntities(page, 20, sort, association, params));
  return result;
};
export const addAttachmentsAgainstPlaylist: ICrudPutAction<IAdminAssociationsManagement> = (
  entity,
  page,
  size,
  sort,
  association
) => async dispatch => {
  console.log('hit2');
  const createUrl = `api/${association}/system/playlist/save`;
  const result = await dispatch({
    type: ACTION_TYPES.ADD_ATTACHMENTS,
    payload: axios.post(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Added !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getEbookList(page, size, sort));
  return result;
};
export const addAttachmentsAgainstVideo: ICrudPutAction<IAdminAssociationsManagement> = (
  entity,
  page,
  size,
  sort,
  association
) => async dispatch => {
  console.log('hit2');
  const createUrl = `api/${association}/references/save`;
  const result = await dispatch({
    type: ACTION_TYPES.ADD_ATTACHMENTS,
    payload: axios.post(createUrl, entity),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Added !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getEbookList(page, size, sort));
  return result;
};
export const removePlaylistAssociation: ICrudDeleteAction<IAdminAssociationsManagement> = (
  associationId,
  association,
  contentType,
  searchValue,
  playListId,
  page,
  size,
  sort,
  sortDirection
) => async dispatch => {
  const deleteUrl = `api/${association}/system/playlist/content/delete`;
  const requestUrl = `${deleteUrl}/${associationId}/${playListId}`;
  const result = await dispatch({
    type: ACTION_TYPES.REMOVE_PLAYLIST_ASSOCIATION,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Removed !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const data = {
    searchValue: searchValue,
    pageNo: page,
    pageSize: size,
    contentId: associationId,
    contentType: contentType,
    sortColumn: sort,
    sortDirection: sortDirection,
  };
  dispatch(getAssociatedPlaylists(data));
  return result;
};
export const removeVideoAssociation: ICrudDeleteAction<IAdminAssociationsManagement> = (
  associationId,
  association,
  contentType,
  searchValue,
  videoId,
  page,
  size,
  sort,
  sortDirection
) => async dispatch => {
  const requestUrl = `api/content/delete/${associationId}/${contentType}/${videoId}/VIDEO`;
  const result = await dispatch({
    type: ACTION_TYPES.REMOVE_PLAYLIST_ASSOCIATION,
    payload: axios.delete(requestUrl),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Removed !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const data = {
    searchValue: searchValue,
    pageNo: page,
    pageSize: size,
    contentId: associationId,
    contentType: contentType,
    sortColumn: sort,
    sortDirection: sortDirection,
  };
  dispatch(getAssociatedVideos(data));
  return result;
};

export const getExistingPlaylistIds: ICrudGetAction<IAdminAssociationsManagement> = (id, association, associateTo) => {
  const getEntityUrl = `api/${association}/system/playlist/ids/${id}`;
  return {
    type: ACTION_TYPES.FETCH_EXISTING_ASSOCIATIONS_IDS,
    payload: axios.get<IAdminAssociationsManagement>(getEntityUrl),
  };
};
export const getSpeakerList = async (): Promise<any> => {
  const geSpeakerUrl = `api/open/AllSpeakers`;
  const data = await axios.get(geSpeakerUrl);
  return data.data;
};
export const getExistingVideoIds: ICrudGetAction<IAdminAssociationsManagement> = (id, association, associateTo) => {
  const getEntityUrl = `api/${association}/references/videoids/${id}`;
  return {
    type: ACTION_TYPES.FETCH_EXISTING_ASSOCIATIONS_IDS,
    payload: axios.get<IAdminAssociationsManagement>(getEntityUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
