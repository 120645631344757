import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Tab, Box } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { NavLink, Row, Table, Col } from 'reactstrap';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntities as getAllVideos, searchVideoList } from '../video-meta-data/video-meta-data.reducer';
import { getEntities as getAllPlayList } from '../admin-playlist/admin-playlist.reducer';

import {
  addAttachmentsAgainstPlaylist,
  addAttachmentsAgainstVideo,
  getExistingPlaylistIds,
  getExistingVideoIds,
  reset
} from './admin-associations-management.reducer';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import Satshrut from '../../../content/images/satshrut3.png';
import { SERVER_API_URL } from 'app/config/constants';
export interface IAdminAssociationAttachmentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ url: string; association: string; id: string,pOrder:string,pPage:string,pSort:string,pSearch:string }> {}
const useStyles = makeStyles(theme => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));

enum Tablist {
  video = 'video',
  playlist = 'playlist',
}
export const AdminAssociationAttachment = (props: IAdminAssociationAttachmentProps) => {
  const {
    playlistEntities,
    match,
    totalVideoItems,
    loading,
    videoLoading,
    playlistLoading,
    videoEntities,
    totalItems,
    existingSelectedIds,
    updateSuccess,
    updating,
    totalPlaylistItems,
  } = props;
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const classes = useStyles();
  const [searchText, setSearchText] = useState(null);
  const [isNewSearch, setIsNewSearch] = useState(false);

  const [activeTab, setActiveTab] = useState<Tablist>(Tablist.video);
  const [imageKey, setImageKey] = useState(true);
  const [videoIds, setvideoIds] = useState<number[]>([]);
  const [playlistIds, setPlaylistIds] = useState<number[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  const [selectedVideosByPage, setSelectedVideosByPage] = useState<number[]>([]);
  const [existingVideoByPage, setExistingVideoByPage] = useState<number[]>([]);
  const [removedVideosFromExisting, setRemovedVideosFromExisting] = useState<number[]>([]);
  const [selectedPlaylistsByPage, setSelectedPlaylistsByPage] = useState<{ id: number }[]>([]);
  const [existingPlaylistsByPage, setExistingPlaylistsByPage] = useState<number[]>([]);
  const [removedPlaylistsFromExisting, setRemovedPlaylistsFromExisting] = useState<{ id: number }[]>([]);
  console.log(existingPlaylistsByPage);
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };
  const isVideoPagination = (sortColumn): Boolean => {
    if (
      sortColumn === 'serialNumber' ||
      sortColumn === 'videoUrl' ||
      sortColumn === 'videoTitle' ||
      sortColumn === 'language' ||
      sortColumn === 'statuses.statusValue'
    ) {
      return true;
    }

    return false;
  };
  const getAllEntities = async() => {
    let result;
    switch (activeTab) {
      case 'playlist':
      result= await props.getExistingPlaylistIds(props.match.params.associationid, props.match.params.association);
        if(result){if (!searchText) {
          props.getAllPlayList(paginationState.activePage - 1, paginationState.itemsPerPage,paginationState.sort,paginationState.order, null,"video");
        } else {
          props.getAllPlayList(
            paginationState.activePage - 1,
            paginationState.itemsPerPage,
            paginationState.sort,paginationState.order,
            searchText,"video"
          );
        }}
        isNewSearch && setIsNewSearch(false);

        break;
      case 'video':
      result= await props.getExistingVideoIds(props.match.params.associationid, props.match.params.association);
        if(result){
          if (!searchText) {
          props.getAllVideos(
            paginationState.activePage - 1,
            paginationState.itemsPerPage,
            `${isVideoPagination(paginationState.sort) ? paginationState.sort : 'serialNumber'}`,
            `${paginationState.order}`
          );
        } else {
          const params = {
            searchValue: searchText,
          };
          props.searchVideoList(
            paginationState.activePage - 1,
            20,
            isVideoPagination(paginationState.sort) ? paginationState.sort : 'serialNumber',
            paginationState.order,
            params
          );
        }}
        isNewSearch && setIsNewSearch(false);

        break;
      default:
        return;
    }
  };
  useEffect(() => {
    if (activeTab === 'video') {
      setExistingVideoByPage(existingSelectedIds);
      setvideoIds(existingSelectedIds);
    } else {
      setExistingPlaylistsByPage(existingSelectedIds);
      setPlaylistIds(existingSelectedIds);
    }
  }, [existingSelectedIds]);
  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${
      activeTab === 'video'
        ? isVideoPagination(paginationState.sort)
          ? paginationState.sort
          : 'serialNumber'
        : isVideoPagination(paginationState.sort)
        ? 'id'
        : paginationState.sort
    },${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useLayoutEffect(() => {
    sortEntities();
    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab]);
  useEffect(() => {
    isNewSearch && paginationState.activePage === 1 && sortEntities();
  }, [isNewSearch]);
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleClose = () => {
    const state = { showTab: props.match.params.association };
    props.reset()
    props.history.push(`/admin-association-management?searchValue=${props.match.params.pSearch}&page=${props.match.params.pPage}&sort=${props.match.params.pSort},${props.match.params.pOrder}`, state);
  };
  const handleSearchButton = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    setIsNewSearch(true);
  };

  const handleSearch = e => {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText(e.target.value);
      !isNewSearch && handleSearchButton();
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setImageKey(prevKey => !prevKey);
      clearInterval(interval);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getImageUrl = item => {
    if (item.thumbnail) {
      const cacheBuster = imageKey ? Date.now() : '';
      return `${SERVER_API_URL}/api/system/playlist/thumbnail/${item.id}?t=${cacheBuster}`;
    }
    return Satshrut;
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if(activeTab!=newValue){
      setShow(false)
      setActiveTab(newValue);
    }

  };
  const isVideosSelected = videoId => {
    return selectedVideosByPage.includes(videoId) || existingVideoByPage.includes(videoId);
  };
  const isPlaylistsSelected = playlistId => {
    console.log(playlistId);
    return selectedPlaylistsByPage.some(data => data.id === playlistId) || existingPlaylistsByPage.includes(playlistId);
  };
  const removeVideosFromExisting = videoId => {
    if (existingVideoByPage.includes(videoId)) {
      const updatedExistingVideoId = existingVideoByPage.filter(id => id !== videoId);
      setExistingVideoByPage(updatedExistingVideoId);
      setRemovedVideosFromExisting(prev => {
        return [...prev, videoId];
      });
    } else if (selectedVideosByPage.includes(videoId)) {
      const updateSelectedByPages = selectedVideosByPage.filter(id => id !== videoId);
      setSelectedVideosByPage(updateSelectedByPages);
    } else {
      return;
    }
  };
  const removePlaylistsFromExisting = playlistId => {
    if (existingPlaylistsByPage.includes(playlistId)) {
      const updatedExistingPlaylistId = existingPlaylistsByPage.filter(id => id !== playlistId);
      setExistingPlaylistsByPage(updatedExistingPlaylistId);
      setRemovedPlaylistsFromExisting(prev => {
        return [...prev, { id: playlistId }];
      });
    } else if (selectedPlaylistsByPage.some(data => data.id === playlistId)) {
      const updatedSelectedPlaylistsByPages = selectedPlaylistsByPage.filter(data => data.id !== playlistId);
      setSelectedPlaylistsByPage(updatedSelectedPlaylistsByPages);
    } else {
      return;
    }
  };

  const handleCheckboxChangeForPlaylist = event => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;

    if (!isPlaylistsSelected(playlistId)) {
      setSelectedPlaylistsByPage(prevSelectedPlaylist => {
        let updatedSelectedPlaylists = [...prevSelectedPlaylist];

        if (checked) {
          if (!updatedSelectedPlaylists.some(data => data.id === playlistId)) {
            updatedSelectedPlaylists.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylists = updatedSelectedPlaylists.filter(data => data.id !== playlistId);
        }

        return updatedSelectedPlaylists;
      });
    } else {
      removePlaylistsFromExisting(playlistId);
    }
  };

  const handleCheckboxChangeForVideo = event => {
    const videoId = parseInt(event.target.dataset.videoId);
    const checked = event.target.checked;
    console.log(videoId, checked);
    if (!isVideosSelected(videoId)) {
      setSelectedVideosByPage(prevSelectedVideos => {
        let updatedSelectedVideos = [...prevSelectedVideos];

        if (checked) {
          if (!updatedSelectedVideos.includes(videoId)) {
            updatedSelectedVideos.push(videoId);
          }
        } else {
          updatedSelectedVideos = updatedSelectedVideos.filter(id => id != videoId);
        }

        return updatedSelectedVideos;
      });
    } else {
      removeVideosFromExisting(videoId);
    }
  };

  const handleSave = () => {
    let data;
    switch (activeTab) {
      case 'playlist':
        data = {
          selectedPalyList: selectedPlaylistsByPage.filter(selected => !playlistIds.includes(selected.id)),
          id: parseInt(props?.match?.params?.associationid),
          unselectedPalyList: removedPlaylistsFromExisting.filter(
            removed => !selectedPlaylistsByPage.some(selected => selected.id === removed.id)
          ),
        };
        props.addAttachmentsAgainstPlaylist(
          data,
          0,
          paginationState.itemsPerPage,
          `${isVideoPagination(paginationState.sort) ? 'id' : paginationState.sort},${paginationState.order}`,
          props.match.params.association
        );

        break;
      case 'video':
        data = {
          referencesContentIds: selectedVideosByPage.filter(id => !videoIds.includes(id)),
          contentId: parseInt(props?.match?.params?.associationid),
          unselectReferencesContentIds: removedVideosFromExisting.filter(id => !selectedVideosByPage.includes(id)),
        };
        props.addAttachmentsAgainstVideo(
          data,
          0,
          paginationState.itemsPerPage,
          `${isVideoPagination(paginationState.sort) ? paginationState.sort : 'serialNumber'},${paginationState.order}`,
          props.match.params.association
        );

        break;
    }
  };
  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  return (
    <div className="main-container">
      <div className="page-title-btn-container">
        <Row className="justify-content-center">
          <Col md="12">
            <div className="detail-page-heading">
              <div className="detail-back-btn">
                <button onClick={() => handleClose()}>
                  <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
                </button>
                <h2 style={{ color: 'rgb(114 108 108)' }}>Associate {props?.match?.params?.association} </h2>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <div className="float-left">
            <div className="search-container">
              <input
                type="search"
                id="search-input"
                className="form-control search-input"
                onChange={e => handleSearch(e)}
                placeholder="Search by title"
                aria-label="Search"
              />
              <button id="search-button" onClick={() => handleSearchButton()}>
                Search
              </button>
            </div>
          </div>
          <div className="float-right common-btn left-margin" style={{ marginRight: 8 }}>
            <NavLink disabled={updating} onClick={handleSave}>
              <span className="header-title">Save</span>
            </NavLink>
          </div>
        </div>
      </div>
      <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
        <Tab label="Videos" value={Tablist.video} className={`${classes.tab} ${activeTab === Tablist.video ? classes.activeTab : ''}`} />
        <Tab
          label="Playlist"
          value={Tablist.playlist}
          className={`${classes.tab} ${activeTab === Tablist.playlist ? classes.activeTab : ''}`}
        />
      </Tabs>
      <div className="table-responsive">
        <div className="table-responsive">
          {activeTab === 'video' && show && !videoLoading &&  (
            <>
              {' '}
              {videoEntities && videoEntities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand">
                        Select
                        <span style={{ float: 'right' }}> {/* <FontAwesomeIcon icon="sort" /> */}</span>
                      </th>
                      <th className="hand" onClick={sort('serialNumber')}>
                        Sr No
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('videoTitle')}>
                        Video Title
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand" onClick={sort('language')}>
                        Language
                        <span style={{ float: 'right' }}>
                          {' '}
                          <FontAwesomeIcon icon="sort" />
                        </span>
                      </th>

                      <th className="hand">
                        Status
                        <span style={{ float: 'right' }}> </span>
                      </th>

                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {videoEntities?.map((videoMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          {' '}
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <input
                              type="checkbox"
                              className="videoCheckbox"
                              data-video-id={videoMetaData.id}
                              checked={isVideosSelected(videoMetaData.id)}
                              onChange={handleCheckboxChangeForVideo}
                              style={{ width: '20px', height: '20px' }}
                            />
                          </div>
                        </td>
                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75 }}>
                          {videoMetaData.serialNumber ? videoMetaData.serialNumber : '--'}
                        </td>

                        <td>{videoMetaData?.videoTitle ? videoMetaData.videoTitle : '--'}</td>

                        <td>{videoMetaData?.language ? videoMetaData.language : '--'}</td>

                        <td>{videoMetaData.statuses.statusValue ? videoMetaData.statuses.statusValue : '--'}</td>
                        {/* <td /> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="alert alert-warning">No video Data found</div>
              )}
            </>
          )}
          {activeTab === 'playlist' && show &&  !playlistLoading && (
            <>
              {playlistEntities && playlistEntities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id="userTableId">
                  <thead>
                    <tr>
                      <th className="hand">
                        Select
                        <span style={{ float: 'right' }}> {/* <FontAwesomeIcon icon="sort" /> */}</span>
                      </th>
                      <th className="hand">Thumbnail</th>
                      <th
                        className="hand"
                        onClick={sort('playlistName')}
                      >
                        Playlist Title
                        <span   style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                      </th>
                      <th onClick={sort('noOfVideos')}  className="hand">Video Count
                      <span style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                      </th>
                      <th onClick={sort('noOfaudios')} className="hand">Audio
                      <span  style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                      </th>

                      <th onClick={sort('noOfEbooks')} className="hand">E-book Count
                      <span  style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                      </th>
                      <th onClick={sort('noOfPads')} className="hand">Pad Count
                      <span  style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                      </th>
                      <th onClick={sort('noOfPatras')} className="hand">Patra Count
                      <span  style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                      </th>
                      <th onClick={sort('noOfTranscript')} className="hand">Transcript Count
                      <span  style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                      </th>
                      <th onClick={sort('createdDate')} className="hand">
                      Created Date
                      <span  style={{ float: 'right' }}>
                        {' '}
                        <FontAwesomeIcon icon="sort" />
                      </span>
                    </th>
                      <th className="hand">
                        Status
                        {/* <span style={{ float: 'right' }}></span> */}
                      </th>
                      {/* <th /> */}
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {playlistEntities?.map((playlistMetaData, i) => (
                      <tr key={`entity-${i}`}>
                        {/* <td>{adminPlaylist.serialNumber ? adminPlaylist.serialNumber : i+1}</td>             */}
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <input
                              type="checkbox"
                              className="videoCheckbox"
                              data-playlist-id={playlistMetaData.id}
                              checked={isPlaylistsSelected(playlistMetaData.id)}
                              onChange={handleCheckboxChangeForPlaylist}
                              style={{ width: '20px', height: '20px' }}
                            />
                          </div>
                        </td>
                        <td>
                          {playlistMetaData && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              {playlistMetaData.thumbnail ? (
                                <img
                                  src={getImageUrl(playlistMetaData)}
                                  style={{ maxWidth: 70, maxHeight: 50 }}
                                  key={playlistMetaData.id}
                                ></img>
                              ) : (
                                <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                              )}
                            </div>
                          )}
                        </td>

                        <td>{playlistMetaData?.playlistName ? playlistMetaData.playlistName : '--'}</td>
                        <td>{playlistMetaData?.noOfVideos ? playlistMetaData.noOfVideos : '--'}</td>
                        <td>{playlistMetaData?.noOfaudios ? playlistMetaData.noOfaudios : '--'}</td>
                        <td>{playlistMetaData?.noOfEbooks ? playlistMetaData.noOfEbooks : '--'}</td>
                        <td>{playlistMetaData?.noOfPads ? playlistMetaData.noOfPads : '--'}</td>
                        <td>{playlistMetaData?.noOfPatras ? playlistMetaData.noOfPatras : '--'}</td>
                        <td>{playlistMetaData?.noOfTranscript ? playlistMetaData.noOfTranscript : '--'}</td>
                        <td>{playlistMetaData?.createdDate ? playlistMetaData.createdDate : '--'}</td>
                        <td>{playlistMetaData?.status ? playlistMetaData?.status : '--'}</td>
                        {/* <td /> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="alert alert-warning">No Playlist data available</div>
              )}
            </>
          )}
        </div>{' '}
        {activeTab == 'playlist' && show  && totalPlaylistItems && !playlistLoading ? (
          <div className={`asso-table ${playlistEntities && playlistEntities.length > 0 ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalPlaylistItems} itemsPerPage={paginationState.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalPlaylistItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
        {activeTab == 'video' && show && props.totalVideoItems && !videoLoading  ? (
          <div className={`asso-table ${videoEntities && videoEntities.length > 0 ? null : 'd-none'}`}>
            <Row className="justify-content-center">
              <JhiItemCount page={paginationState.activePage} total={totalVideoItems} itemsPerPage={paginationState.itemsPerPage} />
            </Row>
            <Row className="justify-content-center">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={props.totalVideoItems}
              />
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ adminAssociationManagement, adminPlaylist, videoMetaData }: IRootState) => ({
  playlistEntities: adminPlaylist.entities,

  loading: adminAssociationManagement.loading,
  videoLoading: videoMetaData.loading,
  playlistLoading: adminPlaylist.loading,
  totalItems: adminAssociationManagement.totalItems,
  template: adminAssociationManagement.template,
  loadingFile: adminAssociationManagement.loadingFile,
  existingSelectedIds: adminAssociationManagement.existingSelectedIds,
  videoEntities: videoMetaData.entities,
  totalVideoItems: videoMetaData.totalItems,
  totalPlaylistItems: adminPlaylist.totalItems,

  updateSuccess: adminAssociationManagement.updateSuccess,
  updating: adminAssociationManagement.updating,
});

const mapDispatchToProps = {
  getAllPlayList,
  getExistingPlaylistIds,
  getExistingVideoIds,
  addAttachmentsAgainstPlaylist,
  addAttachmentsAgainstVideo,
  getAllVideos,
  reset,
  searchVideoList,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminAssociationAttachment);
