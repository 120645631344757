import { IAdminSparshManagement, defaultValue } from 'app/shared/model/admin-sparsh-managemen-model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction } from 'react-jhipster';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ACTION_TYPES = {
  RESET: 'sparsh/RESET',
  FETCH_ENTITIES: 'sparsh/FETCH_ENTITIES',
  FETCH_ENTITY: 'sparsh/FETCH_ENTITY',
  UPLOAD_ALBUM_ID: 'sparsh/UPLOAD_ALBUM_ID',
  UPDATE_STATUS: 'sparsh/UPDATE_STATUS',
  UPLOAD_ENTITY: 'sparsh/UPLOAD_ENTITY',
  UPLOAD_ARTIST: 'sparsh/UPLOAD_ARTIST',
  UPLOAD_THUMBNAIL: 'sparsh/UPLOAD_THUMBNAIL',
  UPLOAD_TRACK: 'sparsh/UPLOAD_TRACK',
  GET_LYRICS: 'sparsh/GET_LYRICS',
  TRACK_DETAILS: 'sparsh/GET_TRACK_DETAILS',

  //NEED TO REMOVE
  ADD_ATTACHMENTS: 'sparsh/ADD_ATTACHMENTS',
  UPDATE_ENTITY: 'sparsh/FETCH_ENTITY',
  DELETE_ENTITY: 'sparsh/DELETE_ENTITY',
  REMOVE_PLAYLIST_ASSOCIATION: 'sparsh/REMOVE_PLAYLIST_ASSOCIATION',
  GET_ALBUMS_DROPDOWNS : 'sparsh/GET_ALBUMS_DROPDOWNS',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAdminSparshManagement>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  template: '',
  loadingFile: null,
  existingSelectedIds: [] as Array<number>,
  isEntityLoad: false,
  totalElements: null,
  loadingSync: false,
  existingPlaylistIds: [] as ReadonlyArray<number>,
  lyricsEntity: null,
  trackDetails: [],
  allAlbumDropDowns:[],
};

export type sparshAlbumManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: sparshAlbumManagementState = initialState, action): sparshAlbumManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENTITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.GET_ALBUMS_DROPDOWNS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        allAlbumDropDowns:[]
      };

    case REQUEST(ACTION_TYPES.FETCH_ENTITIES):
      return {
        ...state,
        entities: [],
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        isEntityLoad: true,
      };

    case REQUEST(ACTION_TYPES.UPLOAD_ENTITY):
      return {
        ...state,
        entities: [],
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        isEntityLoad: true,
      };

    case REQUEST(ACTION_TYPES.UPLOAD_ARTIST):
      return {
        ...state,
        entities: [],
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        isEntityLoad: true,
      };
    case REQUEST(ACTION_TYPES.UPLOAD_THUMBNAIL):
      return {
        ...state,
        entities: [],
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        isEntityLoad: true,
      };
    case REQUEST(ACTION_TYPES.UPLOAD_TRACK):
      return {
        ...state,
        entities: [],
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        isEntityLoad: true,
      };

    case REQUEST(ACTION_TYPES.UPLOAD_ALBUM_ID):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loadingSync: true,
      };

    case REQUEST(ACTION_TYPES.UPDATE_STATUS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loadingSync: true,
      };

    case REQUEST(ACTION_TYPES.GET_LYRICS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loadingSync: true,
      };
    case REQUEST(ACTION_TYPES.TRACK_DETAILS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loadingSync: true,
        trackDetails: [],
      };

    case FAILURE(ACTION_TYPES.FETCH_ENTITIES):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
      };

    case FAILURE(ACTION_TYPES.GET_ALBUMS_DROPDOWNS):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
        allAlbumDropDowns:[]
      };

    case FAILURE(ACTION_TYPES.UPLOAD_ENTITY):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
      };

    case FAILURE(ACTION_TYPES.UPLOAD_ARTIST):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
      };
    case FAILURE(ACTION_TYPES.UPLOAD_THUMBNAIL):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
      };
    case FAILURE(ACTION_TYPES.UPLOAD_TRACK):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
      };

    case FAILURE(ACTION_TYPES.FETCH_ENTITY):
      return {
        ...state,
        loadingFile: false,
        isEntityLoad: false,
        updateSuccess: false,
        loading: false,
      };

    case FAILURE(ACTION_TYPES.UPLOAD_ALBUM_ID):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        loadingSync: false,
      };

    case FAILURE(ACTION_TYPES.UPDATE_STATUS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        loadingSync: false,
      };

    case FAILURE(ACTION_TYPES.GET_LYRICS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        loadingSync: false,
      };

    case FAILURE(ACTION_TYPES.TRACK_DETAILS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        loadingSync: false,
        trackDetails: [],
      };

    case SUCCESS(ACTION_TYPES.FETCH_ENTITIES):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        isEntityLoad: false,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.FETCH_ENTITY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.UPLOAD_ALBUM_ID):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        loadingSync: false,
      };

    case SUCCESS(ACTION_TYPES.GET_ALBUMS_DROPDOWNS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        allAlbumDropDowns: action.payload.data,
        loadingSync: false,
      };

    case SUCCESS(ACTION_TYPES.UPDATE_STATUS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        loadingSync: false,
      };

    case SUCCESS(ACTION_TYPES.UPLOAD_ENTITY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        loadingSync: false,
      };

    case SUCCESS(ACTION_TYPES.UPLOAD_ARTIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        loadingSync: false,
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_THUMBNAIL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        loadingSync: false,
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_TRACK):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        loadingSync: false,
      };

    case SUCCESS(ACTION_TYPES.GET_LYRICS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        lyricsEntity: action.payload.data,
        loadingSync: false,
      };
    case SUCCESS(ACTION_TYPES.TRACK_DETAILS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        // lyricsEntity: action.payload.data,
        loadingSync: false,
        trackDetails: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

// Actions
export const getEntities: ICrudGetAllAction<IAdminSparshManagement> = (page, size, sort, activeTab, searchText) => {
  const entityUrl = `/api/v3/youtube-audio-album`;
  const requestUrl =
    `${entityUrl}` +
    (searchText !== null ? `?searchKey=${searchText}` : '') +
    (searchText !== null ? `&page=${page}` : `?page=${page}`) +
    `&size=${size}` +
    `&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_ENTITIES,
    payload: axios.get<IAdminSparshManagement>(`${requestUrl}`),
  };
};

export const getAllAlbumDropDowns: ICrudGetAllAction<IAdminSparshManagement> = () => {
  // Values for Album DropDown used for Sync
  const page = 0;
  const size = 10;
  const sort = 'id,asc';
  const activeTab = 'album';
  const searchText = null;
  const sizeChnaged = 200;

  const entityUrl = `/api/v3/youtube-audio-album`;
  const requestUrl =
    `${entityUrl}` +
    (searchText !== null ? `?searchKey=${searchText}` : '') +
    (searchText !== null ? `&page=${page}` : `?page=${page}`) +
    `&size=${sizeChnaged}` +
    `&sort=${sort}`;

  return {
    type: ACTION_TYPES.GET_ALBUMS_DROPDOWNS,
    payload: axios.get<IAdminSparshManagement>(`${requestUrl}`),
  };
};


export const getEntity: ICrudGetAction<IAdminSparshManagement> = (id, page, size, sort, activeTab, searchText) => {
  const entityUrl = `/api/v3/youtube-audio-metadata/album/`;
  const requestUrl =
    `${entityUrl}` + `${id}` + `?page=${page}` + `&size=${size}` + `&sort=${sort}` + (searchText ? `&searchKey=${searchText}` : '');
  return {
    type: ACTION_TYPES.FETCH_ENTITY,
    payload: axios.get<IAdminSparshManagement>(requestUrl),
  };
};

export const getTracks: ICrudGetAction<IAdminSparshManagement> = () => {
  const requestUrl = `/api/v3/youtube-audio/sync`;

  return {
    type: ACTION_TYPES.FETCH_ENTITY,
    payload: axios.get<IAdminSparshManagement>(requestUrl),
  };
};

export const getLyrics: ICrudGetAction<IAdminSparshManagement> = trackId => {
  const requestUrl = `/api/v3/youtube-audio-metadata/getLyrics/${trackId}`;

  return {
    type: ACTION_TYPES.GET_LYRICS,
    payload: axios.get<IAdminSparshManagement>(requestUrl),
  };
};

export const getTrackDetailsByTrackId: ICrudGetAction<IAdminSparshManagement> = trackId => {
  const requestUrl = `api/v3/youtube-audio-metadata/admin/${trackId}`;

  return {
    type: ACTION_TYPES.TRACK_DETAILS,
    payload: axios.get<IAdminSparshManagement>(requestUrl),
  };
};

export const updateStatusForAlbum = (id, status) => async dispatch => {
  // status = 'false'
  const requestUrl = `/api/v3/youtube-audio-album/updateStatus/${id}/${status}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_STATUS,
    payload: axios.put(requestUrl),
  });

  if (result.value.status == 200) {
    toast.success('Successfully updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result;
};

export const updateStatusForTrack = (id, status) => async dispatch => {
  // status = 'false'
  const requestUrl = `/api/v3/youtube-audio-metadata/updateStatus/${id}/${status}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_STATUS,
    payload: axios.put(requestUrl),
  });

  if (result.value.status == 200) {
    toast.success('Successfully updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result;
};

export const uploadAlbumId = albumIds => async dispatch => {
  const requestUrl = `/api/v3/youtube-audio/sync`;
  const result = await dispatch({
    type: ACTION_TYPES.UPLOAD_ALBUM_ID,
    payload: axios.post(requestUrl, { albumIds }),
  });

  if (result.value.status == 200) {
    toast.success('Successfully Synced !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result;
};

export const uploadEntity = (audioTrackId, lyrics) => async dispatch => {
  const requestUrl = `/api/v3/youtube-audio-metadata/updateLyrics`;
  const result = await dispatch({
    type: ACTION_TYPES.UPLOAD_ENTITY,
    payload: axios.put(requestUrl, { audioTrackId, lyrics }),
  });

  if (result.value.status == 200) {
    toast.success('Successfully Uploaded !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result;
};

export const uploadArtist = (id, artist) => async dispatch => {
  const requestUrl = ` /api/v3/youtube-audio-metadata/updateArtist`;
  const result = await dispatch({
    type: ACTION_TYPES.UPLOAD_ARTIST,
    payload: axios.put(requestUrl, { id, artist }),
  });

  if (result.value.status == 200) {
    toast.success('Successfully Updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result;
};

export const uploadThumbnail = (audioTrackId, file) => async dispatch => {
  const requestUrl = `api/v3/youtube-audio-album/update-thumbnail`;
  const formData = new FormData();
  formData.append('id', audioTrackId);
  formData.append('image', file);

  try {
    const result = await dispatch({
      type: ACTION_TYPES.UPLOAD_THUMBNAIL,
      payload: axios.put(requestUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    });

    if (result.value.status === 200) {
      toast.success('Successfully Uploaded!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return result;
  } catch (error) {
    toast.error('Something went wrong!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

export const uploadTrack = (audioTrackId, file , duration) => async dispatch => {
  const requestUrl = `api/v3/youtube-audio-metadata/update-audiofile`;
  const formData = new FormData();
  formData.append('id', audioTrackId);
  formData.append('duration', duration);
  formData.append('audioFile', file);

  try {
    const result = await dispatch({
      type: ACTION_TYPES.UPLOAD_TRACK,
      payload: axios.put(requestUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    });

    if (result.value.status === 200) {
      toast.success('Successfully Uploaded!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return result;
  } catch (error) {
    toast.error('Something went wrong!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

export const getSpeakerList = async (): Promise<any> => {
  const geSpeakerUrl = `api/open/AllSpeakers`;
  const data = await axios.get(geSpeakerUrl);
  return data.data;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
